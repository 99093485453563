import Vue from 'vue'
//import VueRouter from 'vue-router';
import store from './store'
import Login from './Login.vue'
import App from './App.vue'

import router from './router'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
Vue.config.productionTip = false


Vue.use(VueMask);

Vue.config.productionTip = false



router.beforeEach((to, from, next) => {
 
 
  if(to.path != '/login'){
    const user = JSON.parse(sessionStorage.getItem('user'))

    if(user == null){
      router.push('login');
    }
 
}
  
      next()
}),




new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
  }).$mount('#app')
  
  //router.replace('/login');
