import { http } from './configOpen'

export default {

    listarvolantes: (tipo) => {
        return http.get('listarvolantes/'+tipo )

    },


    listarPremiados: () => {
        return http.get('listarPremiados' )

    },

    sortear: (volante) => {
        return http.post('sortear/'+ volante )
    },


    liberarAcesso: (acesso) => {
        return http.post('liberarAcesso/'+ acesso )
    },

    

   

}
