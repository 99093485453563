<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Acesso</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              prepend-icon="" v-model="user.username" 
                              label="Login"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="" v-model="user.password" 
                              label="Password"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  @click="handleLogin" color="primary" :loading="loading">Login</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>
import User from './models/user'


export default {
   name: 'Login',
    data () {
      return {
       loading: false,
       user: new User('', ''),
      }
    },


   methods: {
      handleLogin(){     

        this.loading = true
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            (response) => {
              const tipo = response.externo;
                    if(tipo){
                      this.showload = true;
                      this.$router.push('/sorteio')
                      this.loading = false
                    }else{ 
                    
                    this.loading = false
                    this.msg_dialog = 'Usuário ou senha não confere',
                    this.alert_msg = true
                    alert( this.msg_dialog )
                    }

            },
            error => {
              this.alert_msg = true,
              this.loading = false,
              this.msg_dialog = 'Usuário ou senha não confere'
              alert( this.msg_dialog )

            },
          )
        }
      }
    }
}
    
</script>
