import Vue from 'vue'
import Router from 'vue-router'
import Login from './Login.vue'
import Sorteio from './Sorteio.vue'
import Realizando from './Realizando.vue'
import Premio from './Premio.vue'

Vue.use(Router)

export default new Router({
  //mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [


    {
      path: '/',
      component: Login,
   },

    {
      path: '/login',
      component: Login,
   },
   {
      path: '/sorteio',
      component: Sorteio,
   },
   {
    path: '/realizando',
    component: Realizando,
  },
  {
   path: '/premio',
   component: Premio,
 }

  ],
})
