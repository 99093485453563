<template>
<v-app>
		
	<v-dialog v-model="dialog_sortudo" width="800px">

		<v-card class="dlg"> <v-card-title> <span
			class="t09">GANHADORES</span> </v-card-title> <v-card-text>
			<div>
				
				
				<v-list style="height: 400px;overflow-y: scroll;" class="dlg">
					<v-list-item-group>

						<v-list-item v-for="(item, i) in this.lista_sortudos" :key="i"
							class="t010"> 

							<v-list-item-icon> 
									<v-icon dark> mdi-gift </v-icon> 
									
							</v-list-item-icon> 

							<v-list-item-content> 

									<v-list-item-title class="t011" v-text="(i + 1) + ' - ' + item.titular"></v-list-item-title>
								
									<v-list-item-title class="t011" v-text="item.contrato"/> 
									
							</v-list-item-content> 
							
							
						</v-list-item>

				</v-list-item-group>
			</v-list>	
			
		</div>
			</v-card-text> 
				<v-card-actions> <v-spacer></v-spacer> 
				<v-btn
					color="#0C29E7 darken-1" text class="white--text" @click="dialog_sortudo = false">
					OK 
				</v-btn> 
				</v-card-actions> 
		</v-card> 

	</v-dialog>


	

	<div id="topo-b">
		<div style="text-align: center;">
			<span>
				<img src="./assets/img_sorteio_parabens.png" style="width: 30%; margin-top:8%;"  />
			</span>

			<div style="margin-top:8px;">
							
				<span style="text-align: center; font-size: 32px;color:#923a45;font-family: 'Sparky Stones Regular';">
					CUPOM: {{this.sortudo.volante}} <br/>
					{{this.sortudo.titular}} <br/>
					CPF: {{this.sortudo.cpf.substring(0, 6)}} ****** <br/>
					<div 
						v-if="this.sortudo.acao == 'NP'">  Contrato: {{this.sortudo.contrato}} -  NOVO CONTRATO </div>
				
					<div 
						v-if="this.sortudo.acao != 'NP'">  Contrato: {{this.sortudo.contrato}} </div>
					
				</span>
				<div v-if="load_button == true"
				style="text-align: center; font-size: 30px;margin-top:50px">

				<button v-on:click="sortear_ganhador()">
					<span class="myButton">  </span>
				</button>

				
			</div>
		
			</div>

			

		</div>

		
		
			
	</div>
	<p class="texto-inferior-direita">
		<v-btn class="fb-btn btnfloat" fab  dark large 
		@click="dialog_sortudo = true"> 
		<v-icon class="large-icon" dark>mdi-gift</v-icon> 
	</v-btn>
	</p>


</v-app>


</template>

<script>
  import sorteio from './services/sorteio'

export default {
  name: 'Sorteio',
  components: {
  },
   
   mounted(){
      this.lista_premiadosTop3(); //= //sessionStorage.getItem('lista_ganhadores');
	  this.sortudo = JSON.parse(sessionStorage.getItem('ganhador'))
   },


  data(){
    return{
      load: false,
      load_button:true,
      load_contador:false,
      load_tit:true,
      dialog_sortudo:false,
      lista_sortudos:[],
	 
      value: 100,
       icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      sortudo:{
		volante: '********',
        titular: '**************',
        cpf: '***.***.****.**',
        contrato: '*******',
		acao: '',
      },
      interval: {},
      volantes: [],
      volante_sorteado: '',
    }
  },
    methods: {
    formatarContador(value){
        return value / 10;
    },

    achar_sorteado(value){
         sorteio.sortear(value).then(response => {
            this.sortudo = response.data

        }).catch(e => {
        }).finally(() => {
        })

    },

    lista_premiadosTop3(){

       sorteio.listarPremiados().then(response => {
            this.lista_sortudos = response.data  
			sessionStorage.setItem('total', this.lista_sortudos.length)
          
        }).catch(e => {
        }).finally(() => {
           
        })
    },


    sortear_ganhador () {

 			this.$router.push('/realizando')
      },
    }
}
</script>

<style>

.dlg {
	background-color: #923a45 !important;
	font-family: 'poppins-black';
	text-align: left;
	align-self: left;
}

.fb-btn.v-btn--outlined {
    border: solid white !important;
    border-width: 6px !important;
  }
  
  .myButton {
	box-shadow: inset 0px 0px 0px 0px;
	border-radius: 8px;
	display: inline-block;
	cursor: pointer;
	color: #154669;
	font-family: 'emerland';
	font-size: 12pt; /* Defina o tamanho da fonte em pontos */
	width: 660px; /* Defina a largura em pixels ou outra unidade */
	height: 85px; /* Defina a altura em pixels ou outra unidade */
	margin-left: 79px;
	padding: 8px 30px; /* Defina o padding em pixels ou outra unidade */
	background-image: url('./assets/img_button_pag_inicial.png');
	background-size: cover;
  }
  .large-icon {
    font-size: 50px !important; /* Ajuste o tamanho conforme necessário */
  }

.back2{

	background-image: url('./assets/fundo_realizando.png');
	background-repeat: repeat;
	width: 100%;
	height: 100vh;
}

.btnfloat {

	background:#fbe5e9 !important;
	width: 120px !important;
	height: 120px !important;
	border: 8px solid #923a45 !important;
	color: #923a45 !important;
	opacity: 1;
	
}

.myButtonP:hover {
	background: linear-gradient(to bottom, #e69a73 5%, #e69a73 100%);
	background-color: #e69a73;
}

.myButtonP:active {
	position: relative;
	top: 1px;
}

@media only screen and (max-width: 400px) {
	.mobile-hide {
		display: none !important;
	}
}

@media only screen and (max-width: 400px) {
	.mobile {
		display: inline !important;
	}
}

@media only screen and (min-width: 500px) {
	.desktop-hide {
		display: none !important;
	}
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	background-size: cover;
	background-position: center center;
}

hr {
	border: 0;
	width: 70%;
	margin-left: 15%;
	text-align: center;
	border-top: 4px solid #E69A73;
}

.t01 {
	text-align: center;
	margin: 0;
	text-align: center;
	font-weight: 800;
	font-size: 1.2rem;
	font-family: 'poppins-black';
	color: #ffffff;
	padding: 0;
}

.t02 {
	text-align: center;
	margin: 0;
	padding: 0;
}

.t03 {
	text-align: center;
	text-align: center;
	font-size: 2.6rem;
	font-family: 'poppins-black';
	color: #ffffff;
}

.t04 {
	text-align: center;
	font-size: 1.6rem;
	margin-top: 10px;
	font-family: 'poppins';
	color:#ffffff;
}

.t05 {
	text-align: center;
	text-align: center;
	font-size: 1.5rem;
	font-family: 'poppins-medium';
	color: #ffffff;
	font-weight: 600;
}

.t08 {
	font-size: 5.4rem;
	font-weight: 800;
	color: #ffffff;
	font-family: poppins-black
}

.t06 {
	font-size: 2rem;
	font-weight: 800;
	color: #0C29E7;
	font-family: poppins-black
}

.extended-fab {
	width: auto !important;
	border-radius: 100px !important;
	border-color: aqua;
	padding-left: 15px !important;
	padding-right: 15px !important;
  }

.t07 {
	font-size: 1.6rem;
	font-weight: 800;
	color: #0C29E7;
	font-family: poppins
}

.t09 {
	text-align: center;
	font-size: 1.6rem;
	font-family: 'poppins-black';
	color: #ffffff;
	font-size: 1.5rem !important;
}

.t010 {
	text-align: left;
	font-size: 1.2rem;
	font-family: 'poppins';
	color: #ffffff;
}

.t011 {
	font-size: 18px;
	font-family: 'poppins-black';
	color: #ffffff;
}

.t012 {
	font-size: 18px !important;
	width: 100px;
	text-align: left;
	font-family: 'poppins-black';
	color: #ffffff !important;
}

.fundo-topo-e {
	width: 460px;
	height: 50vh;
	opacity: 0.9;

}

.fundo-button-e {
  height: 10vh;
  background-color: pink;
  padding-top: 5%;

}

.topo-i {
	height: 95vh;
	width: 100%;
	padding-top: 5%;
	text-align: center;
}

.fundo-topo-d {
	
	width: 460px;
	height: 50vh;
	opacity: 0.9;

}

.fundo-button-d {
	width: 160px;
	height: 50vh;
	opacity: 0.9;
}


.bg-image-tra-but {
  	width: 40rem;
	height: 50vh;

}


.bg-image {
	opacity: 1 !important;
}

.bg-image-tra {
	opacity: 0.6 !important;
	width: 35rem;
}


.topo {
	height: 100vh;
	width: 100%;

}


.topo-b {
	height: 90vh;
	width: 100%;
}

.topo-c {
	height: 10vh;
	width: 100%;
}

@font-face {
	font-family: 'poppins-black';
	src: url(./assets/fonts/Poppins-Black.ttf);
}

@font-face {
	font-family: 'poppins-extrabold';
	src: url(./assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
	font-family: 'poppins';
	src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
	font-family: 'poppins-medium';
	src: url(./assets/fonts/Poppins-Medium.ttf);
}
</style>
