<template>
<v-app>

	
		<v-dialog v-model="dialog_sortudo" width="800px">

			<v-card class="dlg"> <v-card-title> <span
				class="t09">GANHADORES</span> </v-card-title> <v-card-text>
				<div>
					<v-list style="height: 600px;overflow-y: scroll;" class="dlg">
						<v-list-item-group>
	
							<v-list-item v-for="(item, i) in this.lista_sortudos" :key="i"
								class="t010"> 
	
								
								<v-list-item-icon> 
										<v-icon dark> mdi-gift </v-icon> 
								</v-list-item-icon> 

							
	
								<v-list-item-content> 
									<v-list-item-title class="t011" v-text="(i + 1) + ' - ' + item.titular"></v-list-item-title>
								
									<v-list-item-title class="t011" v-text="item.contrato"/> 
							</v-list-item-content> 
								
								
							</v-list-item>
	
					</v-list-item-group>
				</v-list>	
				
			</div>
				</v-card-text> 
					<v-card-actions> <v-spacer></v-spacer> 
					<v-btn
					text class="white--text t10" @click="dialog_sortudo = false">
						OK 
					</v-btn> 
					</v-card-actions> 
			</v-card> 
	
		</v-dialog>
	
						<div id="topo-b">
							<div class="image-container" style="margin-top:200px">
								<img src="./assets/img_sorteio_1.png" alt="Sorteio Previsao" width="680px">
							</div>
							  
							<div v-if="load_button == true"
								style="text-align: center; font-size: 30px;margin-top:2%">
								<button v-on:click="sortear_ganhador()">
									<span class="myButtonI">  </span>
								</button>
							</div>								
						</div>

					
	
						<p class="texto-inferior-direita">
							<v-btn class="fb-btn btnfloat" fab  dark large 
							@click="dialog_sortudo = true"> 
							<v-icon class="large-icon" dark>mdi-gift</v-icon> 
						</v-btn>
						</p>
			
</v-app>


</template>

<script>
  import sorteio from './services/sorteio'

export default {
  name: 'Sorteio',
  components: {
  },
   
   mounted(){
      this.lista_premiadosTop3(); //= //sessionStorage.getItem('lista_ganhadores');
   },


  data(){
    return{
      load: false,
      load_button:true,
      load_contador:false,
      load_tit:true,
      dialog_sortudo:false,
      lista_sortudos:[],
      value: 100,
       icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      sortudo:{
        titular: '**************',
        cpf: '***.***.****.**',
        contrato: '*******',
      },
      interval: {},
      volantes: [],
      volante_sorteado: '',
    }
  },
    methods: {
    formatarContador(value){
        return value / 10;
    },

  

    lista_premiadosTop3(){

       sorteio.listarPremiados().then(response => {
            this.lista_sortudos = response.data            
        }).catch(e => {
        }).finally(() => {
           
        })
    },


    sortear_ganhador () {
			sessionStorage.setItem('total', parseInt(0))
 			this.$router.push('/realizando')
      },
    }
}
</script>

<style>

@import url('https://fonts.cdnfonts.com/css/emerland-free');

.myButtonI {
	box-shadow: inset 0px 0px 0px 0px;
	border-radius: 8px;
	display: inline-block;
	cursor: pointer;
	color: #154669;
	font-family: 'emerland';
	font-size: 12pt; /* Defina o tamanho da fonte em pontos */
	width: 660px; /* Defina a largura em pixels ou outra unidade */
	height: 85px; /* Defina a altura em pixels ou outra unidade */
	margin-left: 79px;
	padding: 8px 38px; /* Defina o padding em pixels ou outra unidade */
	background-image: url('./assets/img_button_pag_inicial.png');
	background-size: cover;
  }
  

.btnfloat {

	background:#fbe5e9 !important;
	width: 120px !important;
	height: 120px !important;
	border: 8px solid #923a45 !important;
	color: #923a45 !important;
	opacity: 1;
	
}

.large-icon {
    font-size: 50px !important; /* Ajuste o tamanho conforme necessário */
  }




.myButtonI:active {
	position: relative;
	top: 1px;
}


/* Estilos para a div com a imagem do selo */
.selo-container {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-left: 130px; /* Ajuste a margem esquerda conforme necessário */
	margin-bottom: 130px; /* Ajuste a margem inferior conforme necessário */
  }


@media only screen and (max-width: 400px) {
	.mobile-hide {
		display: none !important;
	}
}

@media only screen and (max-width: 400px) {
	.mobile {
		display: inline !important;
	}
}

@media only screen and (min-width: 500px) {
	.desktop-hide {
		display: none !important;
	}
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	background-size: cover;
	background-position: center center;
}

hr {
	border: 0;
	width: 70%;
	margin-left: 15%;
	text-align: center;
	border-top: 4px solid #E69A73;
}


.texto-inferior-direita {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 15px;
}




#topo-a {
	background-color: transparent;
	height: 0%;
	width: 0%;
}


.image-container {
	text-align: center; /* Center the image horizontally within the container */
	margin-top: 8px;

  }

  .rodape-container {
	text-align: center; /* Center the image horizontally within the container */
	position: absolute;
	bottom: 32px; /* Adiciona uma margem de 16px para o fundo da página */
	left: 0;
	width: 100%; 
  }

#topo-b {
	background-image: url('./assets/img_background_1.png');
	height: 100%;
	width: 100%;
	background-size: cover; /* This sets the background size to cover the container */
	background-position: center center;
  	justify-content: center;
	
}

#topo-c {
	position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
	font-family: 'emerland', sans-serif;
    bottom: 0;
	font-size: 22px;
    margin-left: 10px;
    margin-bottom: 5px;
	text-align: center;
}

@font-face {
	font-family: 'poppins-black';
	src: url(./assets/fonts/Poppins-Black.ttf);
}

@font-face {
	font-family: 'emerland';
	src: url(./assets/fonts/emerland.ttf);
}

@font-face {
	font-family: 'poppins-extrabold';
	src: url(./assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
	font-family: 'poppins';
	src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
	font-family: 'poppins-medium';
	src: url(./assets/fonts/Poppins-Medium.ttf);
}
</style>
