<template>
	<v-app>
	<div class="back">
		

	<div class="topo" >
		<div class="row" >
			<div class="col-5">
				
				<div class="mobile-hide"  style="padding-top:16%;"> 
					<div class="t11" >
						<span class="t11" >  
							<img src="./assets/img_mae.png" style="width: 65%; margin-top:8%"  />

						</span>
					</div>
				
				</div>
				
			</div>
			<div class="col-1">


			</div>

			<div class="col-5">
				<div class="topo-i">
       
					<div class="mobile-hide" style="padding-top:16%;">
						<div>
							<span >
								<img src="./assets/img_realizando_sorteio.png" style="width: 85%; margin-top:8%"  />
							</span>
							<div />
						</div>
					</div>					

					<div class="my-2" 
						style="text-align: center; font-size: 42px;">		
					</div>

          			<div
					style="text-align: center; font-size: 42px;color:#923a45;">
					<v-progress-circular :rotate="-90" :size="250" :width="20" 
						:value="value" >
					<span class="t88">{{ formatarContador(value)}}</span> </v-progress-circular>
					</div>
					

				</div>
			</div>
			
		</div>
		
			
	</div>








</div> </v-app>


</template>

<script>
  import sorteio from './services/sorteio'

export default {
  name: 'Sorteio',
  components: {
  },
   

   mounted(){
	  //this.lista_premiadosTop3(); //= //sessionStorage.getItem('lista_ganhadores');
	  this.sortear_ganhador();
   },


  data(){
    return{
      load: false,
      load_button:true,
      load_contador:false,
      load_tit:true,
      dialog_sortudo:false,
      lista_sortudos:[],
      value: 100,
       icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
	  sortudo:{
		volante: '',
        titular: '**************',
        cpf: '***.***.****.**',
        contrato: '*******',
      },
      interval: {},
      volantes: [],
      volante_sorteado: '',
	  
    }
  },
    methods: {
    formatarContador(value){
        return value / 10;
    },

   
	achar_sorteado(value){
         sorteio.sortear(value).then(response => {
            this.sortudo = response.data
			sessionStorage.setItem('ganhador', JSON.stringify(this.sortudo))
			//var total = parseInt(sessionStorage.getItem('total'))
			//sessionStorage.setItem('total', total + parseInt(1))


        }).catch(e => {
        }).finally(() => {
			
        })

    },


	lista_premiadosTop3(){
		sorteio.listarPremiados().then(response => {
			this.lista_sortudos = response.data            
		}).catch(e => {
		}).finally(() => {
			
		})
		},


    sortear_ganhador () {
		var tipo = "CAMPANHA";


	var total = parseInt(sessionStorage.getItem('total'))


	if(total < 2){  

		this.load = false,
        this.load_button = false;
        this.load_contador = true;
        this.value = 100;
        this.load_tit = false,
		
			this.interval = setInterval(() => {
			if (this.value === 10) {
				//if(this.volantes_sorteados.length>0){
						this.load = true,
						this.load_button = true,
						this.load_contador = false,
						clearInterval(this.interval);
							
						this.$router.push('/premio');
						//this.lista_premiadosTop3();
					//}
			}else{
				this.value += -10
			}
			}, 1000)
			

			var pos = this.lista_sortudos.length;
			if(total==1){
				tipo = "CAMPANHA2";
			}
			

			sorteio.listarvolantes(tipo).then(response => {
				this.volantes = response.data
				total++;

			}).catch(e => {
			}).finally(() => {
			//  this.load = false
			if(this.volantes.length>0){
				var randomIndex = Math.floor(Math.random() * this.volantes.length); 
				this.volante_sorteado = this.volantes[randomIndex];
				this.achar_sorteado( this.volante_sorteado);
			}else{
				alert("Não existe cupons para concorrer")
				//this.$router.push('/sorteio')
				this.load_button = true;
				this.load_contador = false;
				this.load_tit = true;
				this.load = false;

			}
				

			})

		}else{
				alert("Sorteio Finalizado!")
				this.$router.push('/sorteio');

		}
      },
    }
}
</script>

<style>

@font-face {
	font-family: 'emerland';
	src: url(./assets/fonts/emerland.ttf);
}

.myButtonS {
	box-shadow: inset 0px 0px 0px 0px;
	background: rgb(52, 30, 197);
	background: linear-gradient(90deg, rgb(12, 41, 231) 0%,
		rgba(12, 41, 231, 1) 50%, rgba(12, 41, 231, 1) 100%);
	border-radius: 8px;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-family: 'poppins-black';
	font-size: 1.6rem;
	padding: 8px 50px;
}

.myButtonS:hover {
	background: linear-gradient(to bottom, #e69a73 5%, #e69a73 100%);
	background-color: #e69a73;
}
.back{
	background-image: url('./assets/img_background_1.png');
	height: 100%;
	width: 100%;
	background-size: cover; /* This sets the background size to cover the container */
	background-position: center center;
  	justify-content: center;

}

.rodape-container {
	text-align: center; /* Center the image horizontally within the container */
	position: absolute;
	bottom: 32px; /* Adiciona uma margem de 16px para o fundo da página */
	left: 0;
	width: 100%; 
  }

.myButtonS:active {
	position: relative;
	top: 1px;
}

@media only screen and (max-width: 400px) {
	.mobile-hide {
		display: none !important;
	}
}

@media only screen and (max-width: 400px) {
	.mobile {
		display: inline !important;
	}
}

@media only screen and (min-width: 500px) {
	.desktop-hide {
		display: none !important;
	}
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
		color: blue;
	background-size: cover;
	background-position: center center;
}

hr {
	border: 0;
	width: 70%;
	margin-left: 15%;
	text-align: center;
	border-top: 4px solid #E69A73;
}

.t11 {
	text-align: center;
	margin: 0;
	text-align: center;
	font-weight: 800;
	font-size: 7.2rem;
	font-family: 'poppins-black';
	color: #653937;
	padding: 0;
}

.t2 {
	text-align: center;
	margin: 0;
	padding: 0;
}

.t33 {
	text-align: center;
	text-align: center;
	font-size: 2.6rem;
	font-family: 'poppins-black';
	color: #653937;
}

.t44 {
	text-align: center;
	font-size: 1.6rem;
	font-family: 'poppins';
	color: #653937;
}

.t55 {
	text-align: center;
	text-align: center;
	font-size: 1.5rem;
	font-family: 'poppins-medium';
	color: #653937;
	font-weight: 600;
}

.t88 {
	color:#923a45;
	font-family: 'Sparky Stones Regular', sans-serif; /* Use a fonte "Sparky Stones Regular" */
	font-size: 9.4rem;
	font-weight: 800;
}

.t66 {
	font-size: 2rem;
	font-weight: 800;
	color: #0C29E7;
	font-family: poppins-black
}

.t77 {
	font-size: 1.6rem;
	font-weight: 800;
	color: #0C29E7;
	font-family: poppins
}

.t99 {
	text-align: center;
	font-size: 1.6rem;
	font-family: 'poppins-black';
	color: #0C29E7;
	font-size: 1.5rem !important;
}

.t110 {
	text-align: left;
	font-size: 1.2rem;
	font-family: 'poppins';
	color: #0C29E7;
}


#topo-c {
	position: absolute;
    background: transparent;
    width: 100%;
    height: 10%;
	font-family: 'emerland', sans-serif;
    bottom: 0;
	font-size: 22px;
    margin-left: 10px;
    margin-bottom: 5px;
	text-align: center;
}


.t111 {
	font-size: 18px;
	font-family: 'poppins-black';
	color: #0C29E7;
}

.t112 {
	font-size: 18px !important;
	width: 100px;
	text-align: left;
	font-family: 'poppins-black';
	color: #653937;
}

.fundo-topo-e {
	width: 460px;
	height: 50vh;
	opacity: 0.9;

}

.fundo-button-ee {
    	width: 460px;
	height: 50vh;
  padding-top: 80%;
  position: relative;
}

.fundo-topo-dd {
	
	width: 460px;
	height: 50vh;
	opacity: 0.9;

}

.fundo-button-d {
	width: 160px;
	height: 50vh;
	opacity: 0.9;
}


.bg-image-tra-but {
  	width: 60rem;
	height: 80vh;

}


.bg-image {
	opacity: 1 !important;
}

.bg-image-tra {
	opacity: 0.6 !important;
	width: 35rem;
}


.topo {
	height: 100vh;
	width: 100%;
}

.topo-i {
	height: 90vh;
	width: 100%;
	padding-top: 5%;
	text-align: center;
}

.topo-b {
	height: 10vh;
	width: 100%;
}

@font-face {
	font-family: 'poppins-black';
	src: url(./assets/fonts/Poppins-Black.ttf);
}

@font-face {
	font-family: 'poppins-extrabold';
	src: url(./assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
	font-family: 'poppins';
	src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
	font-family: 'poppins-medium';
	src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  
 
	font-family: 'Sparky Stones Regular';
	  src:  /* Adicione todos os formatos de fonte necessários */
		   url('./assets/fonts/sparky-stones-regular.woff') format('woff');
	  font-weight: normal;
	  font-style: normal;
	}

</style>
